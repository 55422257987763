<template>
  <div class="page-container payment-method-page">
    <portal to="page-name">Cadastro de forma de pagamento</portal>
    <div class="card card-page">
      <div class="card-header">
        <div class="form-group float-right status-toggle">
          <label class="form-switch">
            <input type="checkbox" v-model="form.active">
            <i class="form-icon"></i> Registro ativo
          </label>
        </div>
        <h1 class="card-title">Cadastro de forma de pagamento</h1>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-8 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label for="name" class="form-label">Nome</label>
            <input type="text" id="name" name="name"
                   v-model="form.name" class="form-input"
                   @change="$v.form.name.$touch()">
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.name.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-4 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.type.$error}">
            <label for="type" class="form-label">Tipo</label>
            <select id="type" name="type" class="form-select"
                    v-model="form.type"
                    @blur="$v.form.type.$touch()">
              <option value="">[Selecione um tipo]</option>
              <option value="expense">Despesas</option>
              <option value="revenue">Receitas</option>
              <option value="all">Todos</option>
            </select>
            <template v-if="$v.form.type.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.type.required">Campo obrigatório</div>
            </template>
          </div>
        </div>
        <div class="columns">
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.bankAccount.id.$error}">
            <label for="bank-account" class="form-label">Conta para compensação</label>
            <select id="bank-account" name="bank-account" class="form-select"
                    v-model="form.bankAccount.id"
                    @change="$v.form.bankAccount.id.$touch()">
              <option value="">[Selecione uma conta]</option>
              <option v-for="(item, i) in bankAccounts"
                      :value="item.id" :key="i">{{ item.name }}</option>
            </select>
            <template v-if="$v.form.bankAccount.id.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.bankAccount.id.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.documentType.id.$error}">
            <label for="document-type" class="form-label">Tipo de documento</label>
            <select id="document-type" name="document-type" class="form-select"
                    v-model="form.documentType.id" @change="onDocumentTypeChange">
              <option value="">[Selecione um tipo de documento]</option>
              <option v-for="(item, i) in documentTypes"
                      :value="item.id" :key="i">{{ item.name }}</option>
            </select>
            <template v-if="$v.form.documentType.id.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.documentType.id.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-2 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.compensation.type.$error}">
            <label for="compensation-type" class="form-label">Compensação</label>
            <select id="compensation-type" name="compensation-type" class="form-select"
                    v-model="form.compensation.type" @change="$v.form.compensation.type.$touch()">
              <option value="same-day">Mesmo dia</option>
              <option value="fixed-day">Dia fixo</option>
              <option value="after-day">Após</option>
            </select>
            <template v-if="$v.form.compensation.type.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.compensation.type.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-2 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.compensation.value.$error}">
            <label for="compensation-value" class="form-label">Dia(s)</label>
            <input type="number" id="compensation-value" name="compensation-value"
                   v-model="form.compensation.value" class="form-input text-center"
                   placeholder="0" @change="$v.form.compensation.value.$touch()"
                   :disabled="form.compensation.type === 'same-day'" v-mask="'00'">
            <template v-if="$v.form.compensation.value.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.compensation.value.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-2 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.installment.$error}">
            <label for="installment-value" class="form-label">Parcelas</label>
            <div class="input-group">
              <input type="number" id="installment-value" name="compensation-value"
                     v-model="form.installment" class="form-input text-center"
                     :disabled="!canEditInstallments" v-mask="'00'"
                     @keypress.enter="changeInstallment"
                     placeholder="0" @blur="$v.form.installment.$touch()">
              <button class="btn btn-gray btn-action input-group-btn btn-icon"
                      @click="changeInstallment" :disabled="!canEditInstallments"
                      tabindex="-1" >
                <fa-icon :icon="['fal', 'sort-numeric-up-alt']"/>
              </button>
            </div>
            <template v-if="$v.form.installment.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.installment.required">Campo obrigatório</div>
            </template>
          </div>
        </div>
        <div class="divider" v-if="form.installments.length > 0"/>
        <div class="installment-table" v-if="form.installments.length > 0">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="text-center" width="25%">Parcela</th>
              <th class="text-center" width="35%">Taxa</th>
              <th class="text-center">Parcelamento</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in form.installments" :key="i">
              <td class="text-center">{{ item.installment }}</td>
              <td class="text-center">
                <div class="input-group">
                  <dx-input-number class="text-center" v-model="item.value"
                                   :precision="2" maxlength="6" />
                  <span class="input-group-addon text-gray">%</span>
                </div>
              </td>
              <td>
                <div class="status-toggle flex-centered">
                  <label class="form-switch">
                    <input type="checkbox" tabindex="-1"
                           v-model="item.interestByMerchant">
                    <i class="form-icon"/>Estabelecimento
                  </label>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1" @click="save()"
                :disabled="saving" :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from 'src/mixins/form';

const typeInstallments = {
  credit_card: 60,
  check: 12,
  debit_card: 1,
  others: 24,
  duplicate: 12,
};

export default {
  mixins: [formMixin],
  data() {
    return {
      isNew: true,
      loading: false,
      saving: false,
      bankAccounts: [],
      documentTypes: [],
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    this.loadBankAccounts();
    await this.loadDocumentTypes();
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  validations() {
    const rules = {
      form: {
        name: { required },
        type: { required },
        installment: {},
        bankAccount: {
          id: { required },
        },
        documentType: {
          id: { required },
        },
        compensation: {
          type: { required },
          value: {},
        },
        active: { required },
      },
    };
    if (this.form.compensation.type !== 'same-day') {
      rules.form.compensation.value = { required };
    }
    return rules;
  },
  computed: {
    currentDocumentType() {
      if (!this.form.documentType.id) {
        return undefined;
      }
      return this.documentTypes.find(({ id }) => id === this.form.documentType.id);
    },
    canHaveInstallments() {
      return this.currentDocumentType
        && this.currentDocumentType.type in typeInstallments;
    },
    maxInstallments() {
      return this.canHaveInstallments
        ? typeInstallments[this.currentDocumentType.type]
        : 0;
    },
    canEditInstallments() {
      return this.maxInstallments > 1;
    },
  },
  methods: {
    onDocumentTypeChange() {
      this.$v.form.documentType.id.$touch();
      if (this.maxInstallments > 0) {
        if (this.form.installment > this.maxInstallments) {
          this.form.installment = this.maxInstallments;
        } else if (this.form.installment === 0) {
          this.form.installment = 1;
        }

        this.changeInstallment();
      } else {
        this.clearInstallments();
      }
    },
    clearInstallments() {
      this.form.installment = 0;
      this.form.installments = [];
    },
    changeInstallment() {
      const { length } = this.form.installments;

      if (length > this.form.installment) {
        this.form.installments = this.form.installments.slice(0, this.form.installment);
      } else {
        for (let i = length; i < this.form.installment; i += 1) {
          this.form.installments.push({
            installment: i + 1,
            value: 0,
            interestByMerchant: true,
          });
        }
      }
    },
    load() {
      this.loading = true;

      return this.$http
        .get(`/payment-methods/${this.form.id}`)
        .then(({ data }) => {
          this.form = Object.assign(this.blankForm(), data, {
            installment: data.installments ? data.installments.length : 0,
          });
          // Update saved method without installments
          if (this.canHaveInstallments && this.form.installment === 0) {
            this.onDocumentTypeChange();
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      if (this.form.installments.length === 0 && this.canHaveInstallments) {
        this.$toast.show('Adicione uma ou mais parcelas', { type: 'error' });
        return;
      }

      this.saving = true;

      const params = {
        name: this.form.name,
        active: this.form.active,
      };

      const { data: found } = await this.$http.get('/payment-methods', { params });

      if (found.items.length > 0 && found.items[0].id !== this.form.id) {
        this.$toast.show('forma de pagamento  já cadastrada', { type: 'error' });
        this.saving = false;
        return;
      }

      const payment = this.clone(this.form);

      if (this.form.compensation.type === 'same-day') {
        payment.compensation.value = 0;
      }

      if (this.form.documentType.id) {
        payment.documentTypeId = this.form.documentType.id;
      }

      if (this.form.bankAccount.id) {
        payment.bankAccountId = this.form.bankAccount.id;
      }

      const request = this.isNew
        ? this.$http.post('/payment-methods', payment)
        : this.$http.put(`/payment-methods/${this.form.id}`, payment);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data }) => {
          if (this.isNew) {
            this.$router.replace(`/financial/payment-methods/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    loadBankAccounts() {
      const params = {
        limit: 0,
        active: true,
      };

      return this.apiGetBankAccounts(params)
        .then((data) => {
          this.bankAccounts = data.items;
        })
        .catch(() => {});
    },
    loadDocumentTypes() {
      const params = {
        limit: 0,
        active: true,
      };

      return this.apiGetDocumentTypes(params)
        .then((data) => {
          this.documentTypes = data.items;
        })
        .catch(() => {});
    },
    apiGetBankAccounts(params) {
      return this.$http.get('/bank-accounts', { params })
        .then(({ data }) => data);
    },
    apiGetDocumentTypes(params) {
      return this.$http.get('/document-types', { params })
        .then(({ data }) => data);
    },
    blankForm() {
      return {
        id: '',
        name: '',
        type: '',
        installment: 0,
        installments: [],
        bankAccount: {
          id: '',
          name: '',
        },
        documentType: {
          id: '',
          name: '',
          type: '',
        },
        compensation: {
          type: 'same-day',
          value: 1,
        },
        active: true,
      };
    },
  },
};
</script>

<style lang="scss">
@import './src/assets/scss/_variables.scss';
  .payment-method-page {
    .installment-table {
      margin: $layout-spacing-lg auto 0 auto;
      max-width: 25rem;
      table {
        th {
          background-color: $gray-color-light;
        }
        border: $border-width solid $border-color;
      }
    }
  }
</style>
